<template>
  <div>
    <p>
      <u>Battle of Nimbus Station</u>
      <br>The action-packed new Battle Instance opens on the 23rd! Travel back in time to when the Battle of Nimbus Station took place by using the time-twister located in Nimbus Station near the launch pad for Avant Gardens.
    </p>
    <p>
      <u>Robot City</u>
      <br>On the 23rd, you can rocket off to Robot City! Go to Starbase 3001 to visit the all-new World Builder League zone! There will be Missions and some fantastic new rocket parts waiting for you!
    </p>
    <p>
      <u>Frostburgh</u>
      <br>- Frostburgh is closing for the season! Did you finish all the Missions? Did you get the extra 2 Backpack slots from the Frostburgh Flag Hunter Achievement? And do you have all the parts for the super pink Mint Condition race car from Frostivus Vendor Hansel Tinsel?
    </p>
    <p>
      <u>Nexus HQ</u>
      <br>- The leaderboards for Footracing, the Shooting Gallery, Survival and the Monument Race are now accessible through Nexus HQ. See how fast your Minifigure is on its feet, and compare your times to your friends’! Note that there will be a delay between what the in-game leaderboards show and what is displayed on Nexus HQ.
    </p>
    <p>
      <u>New Model Sets</u>
      <br>- How would you like to get your hands on the brand new Farm, Mech Bay, Micro City and Doom Star Models? Some can be purchased, others will be rewarded!
    </p>
    <p>
      <u>New Missions and Achievements</u>
      <br>- New Missions for each small Property.
      <br>- New Achievements for visiting other Minifigures’ Properties.
      <br>- New Achievements for placing models on your Property.
      <br>- New Achievements for adding Behaviors to your Models.
    </p>
    <p>
      <u>Where’s Crux Prime?</u>
      <br>- A sign pointing the way to Crux Prime from Nimbus Station has been put up. Pssst, the Crux Prime launch pad is located at the Race Place in Nimbus Station!
    </p>
    <p>
      <u>Crux Prime</u>
      <br>- The Ninjago Scroll in North Aura Mar is now easier to see!
      <br>- The Imagination Spinjitzu pants will now be properly awarded when you complete the Nuckal Cracker Mission, even though your Backpack is full.
      <br>- Speaking of pants, the Bat Lord pants&nbsp;are now available.&nbsp;But they’re rare, so you might not come across them that often!
    </p>
    <p>
      <u>Enemies</u>
      <br>- Spiderlings have become immune to stuns, knockbacks and interruptions. How did that happen??
      <br>- The Stromling Ape Anchor now requires 9 Imagination to Quick Build.
    </p>
    <p>
      <u>Mission change</u>
      <br>- The “Face the Ape” Mission given in Nimbus Station now rewards the consumable item “Anchors Away”, which drops an anchor on an enemy and causes 6 damage. Speaking of new items…
    </p>
    <p>
      <u>New Items</u>
      <br>- Anchors Away
      <br>- Peashooter Mark II
      <br>- Banana Gun
      <br>- Headbash Helmet
      <br>- Howdy Cowboy Hat
      <br>- Flowin’ MC
      <br>- Blaster
      <br>- Savage Club
      <br>- Impact Wrench
      <br>- Witch’s Surf-Broom
      <br>- Padded Toque of Healing
      <br>
      <br>How cool is that!?
    </p>
    <p></p>
  </div>
</template>
